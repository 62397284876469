import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NavMenuComponent from './components/NavMenuComponent';
import { ROUTES } from './constants';
import AppContext from './context/AppContext';
import { IAppContext } from './models/interfaces/IAppContext';
import { IUsuarioSesionContext } from './models/interfaces/IUsuarioSesionContext';

import useRole from './hooks/useRole';
import AgregarUsuarioPage from './views/AgregarUsuarioPage/AgregarUsuarioPage';
import HomePage from './views/HomePage/HomePage';
import LoginPage from './views/LoginPage/LoginPage';
import UsuariosPage from './views/UsuariosPage/UsuariosPage';

const queryClient = new QueryClient();

function App() {
	const [dataUser, setDataUser] = useState<IUsuarioSesionContext>({
		usuario: JSON.parse(localStorage.getItem('credenciales'))?.usuario,
		roles: JSON.parse(localStorage.getItem('credenciales'))?.roles,
		area: JSON.parse(localStorage.getItem('credenciales'))?.area || '',
	});
	const { roleIsMatch } = useRole();

	const contextData: IAppContext = {
		dataUser,
		setDataUser,
	};

	const navegacion = (pagina, route) => {
		if (!!dataUser.usuario) {
			if (roleIsMatch(dataUser.roles, route)) return pagina;
			return <Navigate to={ROUTES.HOME.PATH} />;
		} else {
			return <Navigate to={ROUTES.ROOT.PATH} />;
		}
	};

	return (
		<QueryClientProvider client={queryClient}>
			<AppContext.Provider value={contextData}>
				<main className="d-flex flex-column justify-content-between">
					{!!dataUser.usuario && (
						<>
							<NavMenuComponent />
						</>
					)}
					<div className="flex-grow-1">
						<Routes>
							<Route
								path={ROUTES.ROOT.PATH}
								element={
									!!dataUser.usuario ? (
										<Navigate to={ROUTES.HOME.PATH} />
									) : (
										<LoginPage />
									)
								}
							/>
							<Route
								path={ROUTES.HOME.PATH}
								element={navegacion(<HomePage />, ROUTES.HOME)}
							/>
							<Route
								path={ROUTES.USUARIOS.PATH}
								element={navegacion(<UsuariosPage />, ROUTES.USUARIOS)}
							/>
							<Route
								path={ROUTES.AGREGAR_USUARIO.PATH}
								element={navegacion(
									<AgregarUsuarioPage />,
									ROUTES.AGREGAR_USUARIO
								)}
							/>
						</Routes>
					</div>
				</main>
			</AppContext.Provider>
		</QueryClientProvider>
	);
}

export default App;
